import { bindable } from 'aurelia-framework';
import { BaseButton } from './base-button';

const buttonVariantClasses = {
  primary: 'button-primary',
  secondary: 'button--secondary',
  tertiary: 'button-tertiary',
  ok: 'button-ok',
  warning: 'button-warning',
  danger: 'button-danger',
};

type Variants = keyof typeof buttonVariantClasses;

export class MnButton extends BaseButton {
  @bindable()
  variant: Variants;

  constructor() {
    super();
  }

  attached() {
    if (!this.variant) {
      this.variant = 'primary';
    }

    if (!buttonVariantClasses[this.variant]) {
      throw new Error(
        `Unknown button variant: ${this.variant}, expected one of: ${Object.keys(buttonVariantClasses).join(', ')}`
      );
    }

    this.styleClasses = buttonVariantClasses[this.variant];
  }
}
