import { DialogController } from 'aurelia-dialog';
import { autoinject } from 'aurelia-framework';
import { I18N } from 'aurelia-i18n';

type Action = {
  enabled: boolean;
  t?: string;
};

export type Actions = {
  ok?: Action;
  yes?: Action;
  save?: Action;
  cancel?: Action;
  continueSave?: Action;
  continueDiscard?: Action;
};
export type ConfirmDialogModel = {
  message: string;
  header: string;
  actions: Actions;
  messagePreparsed: boolean;
};

@autoinject
export class Confirm {
  protected message: string;
  protected header: string;
  protected actions: Actions = {
    ok: { enabled: false, t: 'dialog.ok' },
    yes: { enabled: false, t: 'dialog.yes' },
    save: { enabled: true, t: 'dialog.save' },
    cancel: { enabled: true, t: 'dialog.cancel' },
    continueSave: { enabled: true, t: 'dialog.continueSave' },
    continueDiscard: { enabled: false, t: 'dialog.continueDiscard' },
  };

  constructor(protected controller: DialogController, protected i18n: I18N) {}

  public activate(model: ConfirmDialogModel) {
    if (model.actions) {
      this.actions = model.actions;
    }
    if (!model.messagePreparsed) {
      this.message = this.i18n.tr(model.message).split('\n').join('<br/>');
    } else {
      this.message = model.message.split('\n').join('<br/>');
    }

    this.header = this.i18n.tr(model.header);
  }
}
