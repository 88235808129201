import { autoinject, bindable } from 'aurelia-framework';

const validTypes = ['ok', 'warning', 'error', 'info'];
@autoinject
export class Badge {
  @bindable
  public classes = '';

  @bindable
  public type = 'ok';

  attached() {
    if (!validTypes.includes(this.type)) {
      throw new Error(`Invalid badge type ${this.type}`);
    }
  }
}
