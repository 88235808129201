export const filterTypes = {
  CHECKBOX: 'checkbox',
  RADIO: 'radio',
  RADIO_SIMPLE: 'radioSimple',
  RANGE: 'range',
  RANGE_DATE: 'rangeDate',
} as const;

type FilterType = (typeof filterTypes)[keyof typeof filterTypes];

type R = { type: 'checkbox'; values?: { fron?: any; to?: any } };
export type Filter = {
  /**
   * Name of the servicefilter
   */
  name: string;

  /**
   * The label that is used in frontend.
   */
  label: string;

  /**
   * Type of servicefilter. Should reference a constant from filterTypes.
   */
  type: FilterType;

  /**
   * Options to use with the servicefilters. Can be an array, async function or normal function. Must return array.
   * If using RADIO_SIMPLE-type, a string can also be returned.
   */
  options?: Array<any> | string;

  /**
   * Used to reference the options-function. Used to refresh options.
   */
  optionsFunc?: any;

  /**
   * Defines how this servicefilter should be handled when creating query.
   * Accepts both `string` and `function`.
   *
   * If `string`: will use this field as key on query and will use servicefilter's `values` as value.
   *
   * If `function`: the function must return an object containing `key` and `value`.
   */
  query?:
    | string
    | {
        (values?: object):
          | {
              key: string;
              value: string;
            }
          | Array<{
              key: string;
              value: string;
            }>;
      };

  /**
   * This fields contains all set values for the servicefilter.
   */
  values?: string | object | Array<any> | boolean;

  /**
   * Sets default value for the servicefilter.
   */
  defaultValues?: string | object | Array<any> | any;

  /**
   * Creates a custom clear-function instead if default. Does not return anything.
   */
  clear?: (filter?: object, clearFilterByName?: (filterName: string) => void) => void;

  /**
   * Creates a custom clear-function instead if default. Does not return anything.
   */
  change?: (...any) => void;

  /**
   * Used to make filters conditional based on other filters or values.
   */
  disabled?: { (filters?: object) } | boolean | void | any;

  /**
   * Label to show when filter is disabled.
   */
  disabledLabel?: string;

  /**
   * Used as an array-version of values. Mostly used in the frontend.
   */
  _selectedOptions?: any;

  /**
   * Used to set a filter to disabled.
   */
  _disabled?: any;

  /**
   * Helper for frontend to keep dropdown open when an related element - i.e. the datepicker - is open.
   * The datepicker-element is not a child of the servicefilter, and will use this variable to keep the state.
   */
  _childInFocus?: { from: boolean; to: boolean };

  /**
   * Used by localstorage and frontend to show/hide filters from list.
   */
  _visible?: boolean;

  /**
   * Used in view to show/hide the clear-button.
   */
  _clearable?: boolean;

  dropdownRef?: HTMLElement;
};

export interface Filters {
  [key: string]: Filter;
}
