import { autoinject, bindable } from 'aurelia-framework';
import './attention-message.scss';

@autoinject()
export class AttentionMessage {
  @bindable() message: string;
  @bindable() type: 'warning' | 'error' = 'warning';

  attached() {
    if (this.type !== 'warning' && this.type !== 'error') {
      throw new Error('Invalid type provided for AttentionMessage. Use "warning" or "error"');
    }
  }
}
